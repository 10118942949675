<template>
  <section
    class="py-12 overflow-hidden bg-primary bg-opacity-80 md:py-20 relative"
    sectionname="testimonials"
    sectioncomponent="Testimonials"
    sortorder="2"
    site-name="Smartlnks"
    site-logo-url="https://smartlnks-assets.s3.ap-south-1.amazonaws.com/logo.png"
  >
    <div class="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <svg
        class="absolute transform top-full text-primary right-full translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2 rotate-3"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        role="img"
        aria-labelledby="svg-squares"
      >
        <defs>
          <pattern
            id="ad119f34-6583-3b20-836d-4b8c138a10d2"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-primary"
              fill="currentColor"
            ></rect>
          </pattern>
        </defs>
        <rect
          width="404"
          height="404"
          fill="url(#ad119f34-6583-3b20-836d-4b8c138a10d2)"
        ></rect>
      </svg>
      <div class="relative">
        <blockquote>
          <div
            class="max-w-5xl mx-auto text-xl font-bold leading-7 text-center text-white md:leading-10 md:text-2xl text-shadow-sm text-pretty"
          >
            <p>
              SmartLnks: Born out of sheer frustration with mediocre link tools. Our goal?
              To revolutionize link management, because apparently, we're the only ones
              who thought it needed a makeover. Join our not-so-secret mission to make
              every other link tool look like it's from the last century. Elevate your
              online presence, engage like a boss, and unlock possibilities that you
              probably didn't even know existed. Hop on board, and let's show the internet
              how links are really done.
            </p>
          </div>
          <footer class="mt-8">
            <div class="md:flex md:items-center md:justify-center">
              <div class="md:flex-shrink-0"></div>
              <div
                class="mt-3 text-center md:mt-0 md:ml-3 md:flex md:items-center text-shadow-sm"
              >
                <div class="text-lg font-medium text-white">~ FOUNDER</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
</template>

<style></style>
