<script setup>
const services = [
  {
    title: "Branded Domains",
    description:
      "Elevate your brand's digital presence with custom domains. Each link becomes a powerful extension of your brand, enhancing recognition and trust.",
    iconUrl: "/icons/link.png",
    altText: "Branded Domains Icon",
  },
  {
    title: "Advanced Analytics",
    description:
      "Unlock deep insights into link performance with comprehensive analytics. Track clicks, geolocation, device usage, and more to fine-tune your strategies.",
    iconUrl: "/icons/chart.png",
    altText: "Analytics Icon",
  },
  {
    title: "Link Customization",
    description:
      "Transform generic URLs into meaningful, memorable links. Customize links with aliases, password protection, and click limitations for precise audience targeting.",
    iconUrl: "/icons/link-edit.png",
    altText: "Link Customization Icon",
  },
  {
    title: "Dynamic Targeting",
    description:
      "Deliver the right message to the right audience. Utilize device and region-specific targeting to maximize the impact of your links.",
    iconUrl: "/icons/target.png",
    altText: "Targeting Icon",
  },
  {
    title: "Custom Metadata",
    description:
      "Boost your SEO and social media engagement. Add custom metadata to your links for enhanced visibility and shareability.",
    iconUrl: "/icons/connect.png",
    altText: "Custom Meta Icon",
  },
  {
    title: "Link Scheduling",
    description:
      "Plan and execute your link-sharing strategy with precision. Schedule your links to go live at the optimal moment for maximum engagement.",
    iconUrl: "/icons/schedule.png",
    altText: "Link Scheduling Icon",
  },
  {
    title: "In-App Link Opening",
    description:
      "Enhance user experience with seamless in-app link openings. Direct users exactly where you want them, bypassing browser detours.",
    iconUrl: "/icons/link.png",
    altText: "In-App Opening Icon",
  },
  {
    title: "UTM Builder",
    description:
      "Track and optimize your digital campaigns with ease. Utilize our UTM builder for detailed insights into campaign performance and audience behavior.",
    iconUrl: "/icons/utm.png",
    altText: "UTM Builder Icon",
  },
  {
    title: "Pixel Tracking",
    description:
      "Refine your retargeting strategies with pixel tracking. Gain valuable data on user interactions to enhance conversion rates and marketing ROI.",
    iconUrl: "/icons/link.png",
    altText: "Pixel Tracking Icon",
  },
  {
    title: "Permanent YouTube link",
    description:
      "Smartlinks lets you create a permanent link to your YouTube channel that will never expire or go out of date, making it easy for viewers to find and subscribe to your content.",
    iconUrl: "/icons/youtube.png",
    altText: "YouTube Icon",
  },
  {
    title: "And More!",
    description:
      "Discover a myriad of features designed to take your link management to the next level. With Smartlnks, every link is an opportunity.",
    iconUrl: "/icons/plus.png",
    altText: "More Features Icon",
  },
];
</script>
<template>
  <div id="services" class="flex justify-center my-44 scroll-smooth relative">
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center lg:w-[80%]">
      <div class="mb-3 font-bold text-3xl">
        <h2 class="text-900">One Product,</h2>
        <h2 class="text-blue-600">Many Solutions</h2>
      </div>
      <div class="flex justify-center mb-16">
        <h3 class="text-700 mb-6 lg:w-[50%] text-pretty">
          Smartlinks offers a wide range of features to help you organize and optimize
          your links. Here are just a few of the things you can do with Smartlinks
        </h3>
      </div>
      <div class="grid grid-cols-12 gap-5">
        <div
          v-for="service in services"
          :key="service.title"
          class="col-span-12 md:col-span-4 mb-4 px-5"
        >
          <span class="p-3 shadow mb-3 inline-block surface-card rounded-xl">
            <NuxtImg
              provider="awsS3"
              :src="service.iconUrl"
              :alt="service.altText"
              class="h-7 w-7 block-element select-none"
            />
          </span>
          <h4 class="text-900 text-xl mb-3 font-medium">{{ service.title }}</h4>
          <p class="text-700 line-height-3 text-pretty">{{ service.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
