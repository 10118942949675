<template>
  <div
    class="vifnslb-container"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="vifnslb-element" :style="customStyle">
      <div v-for="index in 2" :key="index" class="vifnslb-bar">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  barStyle: {
    type: Object,
    default: () => {},
  },
  duration: {
    type: String,
    default: "12s",
  },
  direction: {
    type: String,
    default: "normal",
  },
  delay: {
    type: String,
    default: "0s",
  },
  paused: {
    type: Boolean,
    default: false,
  },
  pauseOnHover: {
    type: Boolean,
    default: true,
  },
});

const isPaused = ref(props.paused);

watchEffect(() => {
  isPaused.value = props.paused;
});

const customStyle = computed(() => ({
  ...props.barStyle,
  "animation-duration": props.duration,
  "animation-direction": props.direction,
  "animation-delay": props.delay,
  "animation-play-state": isPaused.value ? "paused" : "running",
}));

const handleMouseEnter = () => {
  if (props.pauseOnHover) {
    isPaused.value = true;
  }
};

const handleMouseLeave = () => {
  if (props.pauseOnHover) {
    isPaused.value = props.paused;
  }
};
</script>

<style scoped>
@keyframes moveSlideshow {
  100% {
    transform: translateX(-50%);
  }
}

.vifnslb-container {
  width: 100%;
  overflow: hidden;
}

.vifnslb-element {
  transform: translate3d(0, 0, 0);
  position: relative;
  overflow: hidden;
  animation-name: moveSlideshow;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  width: max-content;
  min-width: 200%;
}

.vifnslb-bar {
  width: 50%;
}
</style>
