<script setup>
const featuresList = [
  "Branded Links + Free SSL",
  "Custom Landing Pages",
  "Personalized 404 Links",
  "Quick Link Shortening",
  "Secure Link Cloaking",
  "App-Ready Deep Links",
  "Time-Limited Links",
  "Clicks Limit Control",
  "Link Expiration Timer",
  "Password-Protected Links",
  "Private & Secure Usage",
  "Warnings & Permissions",
  "Pixel-Based Targeting",
  "Effortless UTM Builder",
  "Open Links in App",
  "Permanent YouTube Links",
  "Real-Time Click Tracking",
  "Smartlinks Preview",
  "Safe Scan Verification",
  "Device-Specific Targeting",
  "Geographical Targeting",
  "Scheduled Link Release",
  "Organized Campaigns",
  "Effective Retargeting",
  "Customizable QR Codes",
  "Intuitive Dashboard",
  "In-depth Activity Logs",
  "Comprehensive Analytics",
  "Automated Report Emails",
  "And Much More!",
];

const featuresPerSlide = 6;
const slides = computed(() => {
  const slices = [];
  for (let i = 0; i < featuresList.length; i += featuresPerSlide) {
    slices.push(featuresList.slice(i, i + featuresPerSlide));
  }
  return slices;
});
</script>

<template>
  <div
    class="relative flex flex-wrap mt-8 mb-64 md:mx-72 mx-2 gap-3 z-30 overflow-hidden before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']"
  >
    <template v-for="(slideFeatures, index) in slides" :key="slideFeatures">
      <infinite-slide-bar
        class="features-slide-bar"
        :duration="'40s'"
        :direction="index % 2 === 0 ? 'normal' : 'reverse'"
      >
        <div v-for="feature in slideFeatures" :key="feature">
          <span class="bg-gray-900 block rounded-lg px-7 py-3 mx-4 cursor-default">
            <span class="text-white text-sm">{{ feature }}</span>
          </span>
        </div>
      </infinite-slide-bar>
    </template>
  </div>
</template>
<style lang="postcss">
.features-slide-bar .vifnslb-bar {
  @apply flex flex-row  items-center;
}
</style>
