<script setup>
const storySections = [
  {
    number: "01",
    title: 'The Idea - "From Frustration to Eureka!"',
    paragraphs: [
      "Our journey began with a simple annoyance: Why couldn’t we just open links directly in our favorite apps? Dealing with clunky browsers felt like trying to text with a rotary phone. So, we rolled up our sleeves and dreamed up Smartlinks - a tool not just for opening links, but for catapulting them into the future.",
    ],
  },
  {
    number: "02",
    title: 'Early Days - "Our Humble Brag"',
    paragraphs: [
      "- In 2021, we unleashed Smartlinks on ourselves, a tool so nifty it almost asked for a patent on coolness. Custom aliases, subdomains, the works - it was like giving a caveman a smartphone.",
      "- We soon realized we weren't the only ones in need of a link revolution. So, we generously decided to share our brilliance with the world.",
    ],
  },
  {
    number: "03",
    title: 'Growth - "Going Viral, But in a Good Way"',
    paragraphs: [
      "- Turns out, our little invention was a hit. Who knew managing links could be as addictive as scrolling social media?",
      "- We kept adding bells and whistles based on what people said they wanted. Bots rights? Geo-targeting? Sure, why not throw in the kitchen sink too?",
    ],
  },
  {
    number: "04",
    title: 'Today - "Sitting on Our Throne"',
    paragraphs: [
      "Fast forward to now, and Smartlinks is the go-to guru for link management. Opening links in native apps? Check. Managing links while sipping coffee? Double-check.",
    ],
  },
  {
    number: "05",
    title: 'Our Mission - "Saving the World, One Link at a Time"',
    paragraphs: [
      "At Smartlinks, we're on a mission to make link sharing not just easy but ridiculously fun. Whether you're an influencer or a corporate giant, we've got the magic wand for your link woes.",
    ],
  },
  {
    number: "06",
    title: 'Our Vision - "Conquering the Link Universe"',
    paragraphs: [
      "The future? We see a world where link sharing is a breeze, and Smartlinks is the superhero in this story. We're committed to innovating, one crazy idea after another.",
    ],
  },
];
</script>
<template>
  <div class="my-44" id="our-story">
    <div
      class="flex justify-center bg-contain bg-no-repeat bg-[url('https://smartlnks-assets.s3.ap-south-1.amazonaws.com/bg_03.webp')]"
    >
      <div class="grid grid-cols-2 lg:w-[80%] w-[90%] gap-10">
        <div class="col-span-2 lg:col-span-1 md:col-span-1 text-left lg:w-[80%] z-10">
          <h2 class="font-bold text-xl text-primary">OUR STORY</h2>
          <h3 class="font-bold text-xl mt-5">
            Revamping link management: simpler, smarter, more effective. Because
            old-school link handling? That's ancient history.
          </h3>
          <div class="mt-10 flex">
            <div class="w-[30%] pt-5">
              <NuxtImg
                provider="awsS3"
                class="bg-cover select-none pointer-events-none"
                src="/plane.webp"
                alt="image"
              />
            </div>
            <div class="w-[70%]">
              <p class="text-pretty">
                Smartlinks started with a dream: to make link management a breeze, not a
                brain teaser. We knew there had to be a better way than juggling a dozen
                tabs. So, we built a tool that's not just powerful, but also as easy as
                pie. Now, we're on a never-ending quest to add more bells and whistles,
                helping our users stand out online without breaking a sweat.
              </p>
              <SvgDotsGrid :additionalClass="'absolute right-0 !mt-[20px] lg:m-0 p-6'" />
            </div>
          </div>
        </div>
        <div class="col-span-2 lg:col-span-1 md:col-span-1 text-left z-10">
          <div v-for="(section, index) in storySections" :key="index">
            <div class="flex my-5">
              <h3 class="font-bold text-xl mr-2">{{ section.number }}.</h3>
              <div>
                <h4 class="font-bold text-xl">{{ section.title }}</h4>
                <p
                  v-for="(paragraph, pIndex) in section.paragraphs"
                  :key="pIndex"
                  class="flex w-[90%] justify-end text-pretty"
                >
                  {{ paragraph }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
