<script setup></script>
<template>
  <div id="our-mission" class="my-44 relative">
    <div class="flex justify-center">
      <div class="grid grid-cols-2 lg:w-[80%] w-[90%] gap-10">
        <div class="col-span-2 md:col-span-1 text-left">
          <h2 class="font-bold text-4xl md:text-5xl">OUR MISSION</h2>
          <p class="mt-10 lg:w-[80%] text-pretty">
            "At SmartLnks, we're on a noble crusade to rescue the world from the tyranny
            of mediocre link management. Our mission? To empower creators, businesses, and
            organizations to not just reach, but genuinely captivate their audiences.
            We're not just simplifying link sharing we're reinventing it with a dash of
            flair and a truckload of intuitiveness. Our platform isn't just a tool it's a
            revolution in link management, constantly evolving like a tech chameleon to
            meet your wildest needs. We're not just aiming to be the go-to tool for link
            management we're aiming to be the only one you ever dream of. Our passion? To
            see our users not just succeed, but absolutely dominate their digital realms.
            So, come aboard our mission to transform the mundane into the extraordinary,
            one link at a time."
          </p>
        </div>
        <div class="col-span-2 md:col-span-1">
          <NuxtImg
            provider="awsS3"
            src="/bg_05.png"
            alt="bg-05"
            class="select-none pointer-events-none h-full"
            :class="{ 'w-full': $device.isMobile }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
