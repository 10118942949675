<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";
import { showUnauthHistorySidebar } from "~/utils/sidebarActions.js";

const op = ref(true);

const { isAuthenticated } = storeToRefs(useAuthStore());

const toggle = (event) => {
  op.value.toggle(event);
};
</script>
<template>
  <div v-if="!isAuthenticated" class="md:left-[-3rem] left-[-2.5rem] absolute top-2 z-30">
    <Button
      class="hover:scale-125"
      icon="pi pi-history"
      @click="showUnauthHistorySidebar()"
      type="button"
      severity="secondary"
      text
      rounded
      aria-label="history"
      v-tooltip.bottom="{
        value: 'My SmartLnks History',
        class: 'extra-small-3xl-tooltip',
      }"
    />
    <!-- <OverlayPanel ref="op" id="unauth-history-overlay-panel" :showCloseIcon="false">
      <HomeHistoryOverlayPanelBody />
    </OverlayPanel> -->
  </div>
</template>

<style lang="postcss">
.p-overlaypanel-close {
  @apply !bg-gray-200;
}
#unauth-history-overlay-panel {
  @apply w-full md:w-[26rem] rounded-lg shadow-2xl;
}
#unauth-history-overlay-panel > .p-overlaypanel-content {
  @apply bg-gray-100 rounded-lg overflow-hidden h-96 md:w-[28rem] w-full;
}
#unauth-history-overlay-panel.p-overlaypanel.p-overlaypanel-flipped:after {
  @apply border-t-gray-100;
}
</style>
