<script setup>
const isClient = ref(false);

onMounted(() => {
  isClient.value = true;
});
</script>

<template>
  <ClientOnly>
    <div v-if="isClient">
      <HomeSmartenItBtnClient />
    </div>
  </ClientOnly>
  <HomeSmartenItBtnServer v-if="!isClient" />
</template>

<style lang="postcss">
#smarten-it-btn {
  @apply text-white absolute right-2.5 bottom-2.5 !bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-3 dark:bg-black dark:hover:bg-black dark:focus:ring-black border-none hover:scale-105 z-30;
}
</style>
