<script setup>
import { form, formV$, resetForm } from "~/validations/homeLinkFormRules";
import {
  showCustomLinkOptionsDialog,
  showLinkCreatedDialog,
} from "~/utils/dialogActions";
import { notifyError } from "~/utils/notifications";
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore";
import { storeToRefs } from "pinia";
import { isPublicDomainUrl } from "~/utils/urls";

const { isSubmitted, loading } = storeToRefs(useHomeLinkFormStore());

async function onSmartenItClick(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    notifyError({
      title: !form.destinationURL.length
        ? "Please enter or paste any url"
        : "Please enter a valid url address",
      text: "",
    });
    return;
  } else if (isPublicDomainUrl(form.destinationURL)) {
    const errorMessage =
      "This URL has already been smartened. Please provide a new URL to smarten.";
    notifyError({ title: errorMessage, text: "" });
    return;
  }

  // if (youtubeRegex.test(form.destinationURL)) {
  //   const slYouTubeURL = form.destinationURL
  //     .replace("youtu.be", "slyoutu.be")
  //     .replace("youtube.com", "slyoutube.com");
  //   showLinkCreatedDialog({
  //     link: slYouTubeURL,
  //     didYouKnow:
  //       'You can unlock the ancient secret of opening YouTube links in the app by simply adding "sl" before the URL. So, youtu.be/videoId becomes slyoutu.be/videoId. It\'s almost like rocket science... but not really. 😉',
  //     showMoreOptions: false,
  //   });
  //   resetForm();
  //   return;
  // }

  showCustomLinkOptionsDialog({
    destinationUrl: form.destinationURL,
    callback: null,
  });
  return;
}
</script>
<template>
  <Button
    @click="onSmartenItClick(formV$.$invalid)"
    :loading="loading"
    id="smarten-it-btn"
    :label="loading ? 'Smartening It...' : 'Smarten It'"
    v-tooltip.right="{
      value:
        'Click to instantly transform your URL into a smart link with enhanced tracking and management features.',
      class: 'extra-small-xl-tooltip',
    }"
  />
</template>

<style lang="postcss">
#smarten-it-btn {
  @apply text-white absolute right-2.5 bottom-2.5 !bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-3 dark:bg-black dark:hover:bg-black dark:focus:ring-black border-none hover:scale-105 z-30;
}
</style>
