<script setup></script>
<template>
  <Button
    id="smarten-it-btn"
    label="Smarten It"
    v-tooltip.right="{
      value:
        'Click to instantly transform your URL into a smart link with enhanced tracking and management features.',
      class: 'extra-small-xl-tooltip',
    }"
  />
</template>

<style lang="postcss">
#smarten-it-btn {
  @apply text-white absolute right-2.5 bottom-2.5 !bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-3 dark:bg-black dark:hover:bg-black dark:focus:ring-black border-none hover:scale-105 z-30;
}
</style>
