<template>
  <div
    class="md:px-8 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9"
  >
    <div
      class="mx-auto w-11/12 md:w-3/4 relative flex-row md:flex justify-center items-center"
    >
      <HomeUnauthHistoryButton />
      <HomeSmartenItForm />
      <CustomAgreementText class="block md:hidden" />
      <SvgTryOutNow class="hidden lg:block md:block absolute right-[-11.5rem]" />
      <SvgGiveItATry class="block lg:hidden md:hidden mt-3 w-full" />
    </div>
  </div>
  <CustomAgreementText class="hidden md:block" />
</template>

<script setup></script>

<style scoped></style>
