<script setup>
import { createAvatar } from "@dicebear/core";
import { micah } from "@dicebear/collection";
import CountryFlag from "vue-country-flag-next";
const { isMobile } = useDevice();

const generateAvatar = (seed) => {
  return createAvatar(micah, {
    seed: seed,
    size: 45,
  }).toDataUriSync();
};

const initialLength = ref(isMobile ? 4 : 8);

const testimonials = ref([
  {
    name: "Sophia Turner",
    title: "Brand Manager",
    country: "United States",
    country_code: "US",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks didn't just streamline our link management, it redefined it. The branded domains and alias features have transformed how we present our brand to the world. It's a game-changer for digital branding.",
    link: "https://twitter.com/sophiaturner",
  },
  {
    name: "Luis Ramirez",
    title: "Marketing Director",
    country: "Spain",
    country_code: "ES",
    avatar: "link_to_avatar_image",
    text:
      "El poder programar enlaces y su apertura directa en aplicaciones ha revolucionado nuestra estrategia de contenido. Smartlnks es una herramienta indispensable para el marketing digital moderno.",
    link: "https://twitter.com/luisramirez",
  },
  {
    name: "Aarav Patel",
    title: "SEO Specialist",
    country: "India",
    country_code: "IN",
    avatar: "link_to_avatar_image",
    text:
      "उन्नत UTM प्रबंधन और SEO अनुकूलन के साथ, Smartlnks ने हमारे अभियानों को नयी ऊंचाइयां दी हैं। यह वास्तव में लिंक प्रबंधन का भविष्य है।",
    link: "https://twitter.com/aaravpatel",
  },
  {
    name: "Emma Wilson",
    title: "Content Creator",
    country: "Australia",
    country_code: "AU",
    avatar: "link_to_avatar_image",
    text:
      "With Smartlnks, my links are not just shorter, they're smarter. The in-app opening feature alone has significantly improved my audience's experience. It's a content creator's dream tool.",
    link: "https://twitter.com/emmawilson",
  },
  {
    name: "Yuto Nakamura",
    title: "App Developer",
    country: "Japan",
    country_code: "JP",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnksは私たちのアプリマーケティング戦略を変革しました。アプリ内オープンリンクはユーザーエクスペリエンスを向上させ、リターゲティングが容易になりました。",
    link: "https://twitter.com/yutonakamura",
  },
  {
    name: "Chloé Dubois",
    title: "Digital Strategist",
    country: "France",
    country_code: "FR",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks a changé la donne avec sa précision de suivi et ses insights géographiques. C’est devenu un atout incontournable pour mes campagnes numériques.",
    link: "https://twitter.com/chloedubois",
  },
  {
    name: "Aliyah Khan",
    title: "Social Media Influencer",
    country: "United Arab Emirates",
    country_code: "AE",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks has turned every link I share into a storytelling opportunity. The metadata customization ensures that my audience sees a consistent brand image, no matter where they find my links.",
    link: "https://twitter.com/aliyahkhan",
  },

  // Testimonial from India in Hindi
  {
    name: "Priya Gupta",
    title: "Digital Marketing Manager",
    country: "India",
    country_code: "IN",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks के साथ, मैंने न केवल अपने लिंक्स को छोटा किया है, बल्कि उन्हें और भी उपयोगी बनाया है। यह टूल हर डिजिटल मार्केटर के लिए एक वरदान है।",
    link: "https://twitter.com/priyagupta",
  },

  // Testimonial from USA in English
  {
    name: "Michael Brown",
    title: "SEO Consultant",
    country: "United States",
    country_code: "US",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks is revolutionizing link management with unparalleled features. The UTM parameters and direct app opening have been a game-changer for my campaigns.",
    link: "https://twitter.com/michaelbrown",
  },

  // Testimonial from Indonesia in Indonesian
  {
    name: "Ayu Putri",
    title: "Content Strategist",
    country: "Indonesia",
    country_code: "ID",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks memberikan kecerdasan pada setiap tautan yang saya bagikan. Fitur pelacakan dan pembukaan aplikasi langsung sangat membantu dalam strategi konten saya.",
    link: "https://twitter.com/ayuputri",
  },

  // Testimonial from UK in English
  {
    name: "Olivia Smith",
    title: "Marketing Director",
    country: "United Kingdom",
    country_code: "GB",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks has elevated our link management to new heights. The branded links and advanced analytics provide deep insights that are critical for our success.",
    link: "https://twitter.com/oliviasmith",
  },

  // Testimonial from Pakistan in Urdu
  {
    name: "Ahmed Khan",
    title: "Social Media Manager",
    country: "Pakistan",
    country_code: "PK",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks نے لنک مینجمنٹ کو ایک نئی جہت دی ہے۔ اس کے استعمال سے ہماری رسائی میں نمایاں اضافہ ہوا ہے۔",
    link: "https://twitter.com/ahmedkhan",
  },

  // Testimonial from Russia in Russian
  {
    name: "Ivan Petrov",
    title: "Digital Innovator",
    country: "Russia",
    country_code: "RU",
    avatar: "link_to_avatar_image",
    text:
      "С Smartlnks управление ссылками стало настоящим искусством. Брендированные домены и глубокая аналитика - это то, что делает наш бренд узнаваемым.",
    link: "https://twitter.com/ivanpetrov",
  },

  // Testimonial from Brazil in Portuguese
  {
    name: "Gabriela Silva",
    title: "E-commerce Specialist",
    country: "Brazil",
    country_code: "BR",
    avatar: "link_to_avatar_image",
    text:
      "Smartlnks transformou a gestão dos nossos links com suas funcionalidades avançadas. A capacidade de abrir links diretamente nos aplicativos tem sido incrivelmente eficaz.",
    link: "https://twitter.com/gabrielasilva",
  },

  // Testimonial from Philippines in Filipino
  {
    name: "Maria Santos",
    title: "Entrepreneur",
    country: "Philippines",
    country_code: "PH",
    avatar: "link_to_avatar_image",
    text:
      "Ang Smartlnks ay nagbigay daan para sa mas mahusay na pamamahala ng mga link. Ang pag-track ng metadata at pag-retarget ay nagpapahusay sa aming marketing strategies.",
    link: "https://twitter.com/mariasantos",
  },
]);
</script>

<template>
  <section id="testimonies" class="mt-44 mb-32 relative z-30">
    <div class="lg:w-[80%] w-[90%] mx-auto">
      <!-- Section Header -->
      <div class="mb-12 space-y-5 md:mb-16 md:text-center">
        <h2
          class="inline-block px-3 py-1 text-sm font-semibold text-indigo-50 rounded-lg md:text-center text-cn bg-black bg-opacity-80 hover:cursor-pointer hover:bg-opacity-60"
        >
          Words from Others
        </h2>
        <h2 class="mb-5 text-3xl font-semibold text-slate-900 md:text-center md:text-5xl">
          It's not just us.
        </h2>
        <h3 class="text-xl text-slate-700 md:text-center md:text-2xl">
          Here's what others have to say about us.
        </h3>
      </div>

      <transition name="fade">
        <masonry-wall
          :items="testimonials.slice(0, initialLength)"
          :ssr-columns="10"
          :column-width="300"
          :gap="20"
        >
          <template #default="{ item }">
            <div class="text-sm leading-6 relative group shadow hover:scale-105">
              <!-- Testimonial Content -->
              <div
                class="absolute transition rounded-lg opacity-25 inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
              ></div>
              <button class="cursor-pointer">
                <!-- bg-slate-800 ring-gray-900/5 -->
                <div
                  class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1"
                >
                  <div class="flex items-center text-left space-x-4">
                    <NuxtImg
                      :src="generateAvatar(item?.name)"
                      class="w-12 h-12 bg-center bg-cover border rounded-full"
                      :alt="item?.name"
                    />
                    <div>
                      <h4 class="text-lg font-semibold text-gray-900">
                        {{ item?.name }}
                        <DeferredContent class="inline">
                          <country-flag
                            :country="item?.country_code"
                            size="small"
                            :shadow="true"
                            :rounded="false"
                          />
                        </DeferredContent>
                      </h4>
                      <p class="text-gray-500 text-md">{{ item?.title }}</p>
                    </div>
                  </div>
                  <p class="leading-normal text-gray-700 text-md text-pretty">
                    {{ item?.text }}
                  </p>              
                </div>
              </button>
            </div>
          </template>
        </masonry-wall>
      </transition>

      <div
        v-if="initialLength != testimonials.length"
        class="inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-white pt-32 pb-8 dark:from-slate-900 absolute"
      >
        <Button
          label="Show more..."
          class="px-6 !py-2.5 !bg-black !border-none"
          @click="initialLength = testimonials.length"
        />
      </div>
    </div>
  </section>
</template>

<style></style>
