<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

const { isAuthenticated } = storeToRefs(useAuthStore());

defineProps({
  label: {
    type: String,
    default: "Sign Up Free",
  },
});
</script>

<template>
  <div>
    <NuxtLink
      v-if="!isAuthenticated"
      to="/signup"
      class="w-full inline-block px-4 py-2 my-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-black rounded-md focus:outline-none focus:ring focus:ring-opacity-80"
    >
      {{ label }}
    </NuxtLink>
  </div>
</template>

<style></style>
