<script setup></script>

<template>
  <div
    class="flex flex-row justify-center mt-32 scroll-smooth relative z-30"
    id="quick-features"
  >
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center lg:w-[80%]">
      <h2 class="text-2xl md:text-4xl font-bold text-black">
        Unlock Rapid Business Growth with Our Product
      </h2>
      <h3 class="mt-8 text-gray-500">
        Experience the Unbeatable Power of Our Tool and Achieve Unprecedented Success -
        Try it Now!
      </h3>
    </div>
  </div>
  <HomeQuickFeaturesCarousel />
</template>

<style></style>
