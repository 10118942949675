import { isPublicDomainUrl } from "~/utils/urls";

export const useClipboardWatcher = () => {
  const clipboardUrl = ref("");
  const seenUrls = new Set();
  let intervalId = null;

  const checkClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const url = new URL(text);
      if (!seenUrls.has(url.href) && !isPublicDomainUrl(url)) {
        seenUrls.add(url.href);
        clipboardUrl.value = url.href;
      } else {
        clipboardUrl.value = "";
      }
    } catch (err) {
      clipboardUrl.value = "";
    }
  };

  const startWatchingClipboard = () => {
    intervalId = setInterval(checkClipboard, 1000); // Check every 1 second
  };

  const stopWatchingClipboard = () => {
    clearInterval(intervalId);
  };

  onMounted(startWatchingClipboard);
  onUnmounted(stopWatchingClipboard);

  return { clipboardUrl };
};
