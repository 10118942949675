<script setup>
import { useClipboardWatcher } from "~/composables/useClipboardWatcher";
import { showClipboardDialog } from "~/utils/dialogActions";

const { clipboardUrl } = useClipboardWatcher();

watch(clipboardUrl, (newUrl) => {
  if (newUrl) {
    showClipboardDialog({ url: newUrl });
  }
});
</script>
<template>
  <div></div>
</template>

<style lang="postcss" scoped></style>
