<script setup></script>

<template>
  <!-- bg-gray-50 -->
  <div class="overflow-x-hidden  !pt-36">
    <HomeHeroSectionGradientBg />
    <section class="pt-12 sm:pt-16">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center relative">
          <HomeHeroHeadline />
          <HomeHeroCta />
          <p class="mt-10 text-base text-gray-500 font-inter">
            Free, Lightning-Fast, Secure, The Last Link Tool You'll Ever Need.
          </p>
        </div>
      </div>

      <LazyHomeHeroImage />
    </section>
  </div>
</template>

<style lang="postcss">
#typewriter-span > label {
  @apply text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-[3.5rem] lg:leading-tight;
}
</style>
