<script setup>
const faqs = ref([
  {
    id: 1,
    question: "What Is SmartLinks?",
    answer:
      "SmartLnks is a dynamic link management platform that transforms your URLs into powerful marketing tools. It offers branded domains, in-depth analytics, custom aliases, and more, making your links smarter and more engaging.",
  },
  {
    id: 2,
    question: "How Do I Create a SmartLink with SmartLnks?",
    answer:
      "Creating a SmartLink is a breeze with SmartLnks. Just enter any URL and tap into our customization tools. Choose a branded domain, schedule your link, set a password, or target specific locations or devices. Each feature is designed to make your link not just a connector, but a standout performer in your digital strategy.",
  },
  {
    id: 3,
    question: "Is SmartLnks Safe?",
    answer:
      "Safer than a vault! We scan every link for nasties. If we spot anything fishy, it's goodbye link, hello safety. We're like the digital bodyguards of your links.",
  },
  {
    id: 4,
    question:
      "How is SmartLnks Better Than AppOpener, OpenInApp, or Other URL Shorteners?",
    answer:
      "Think of us as the Swiss Army knife of link tools. Unlike AppOpener and OpenInApp, we're not just a one-trick pony. Custom aliases, dashboards, speed - we've got it all!",
  },
  {
    id: 5,
    question: "How to Open YouTube Links in App with SmartLnks?",
    answer:
      "Just add 'sl' before any YouTube URL, and bam - you're in the app! We're the only ones doing it this slick and quick. 'https://slyoutu.be/watch?v=xyz', see?",
  },
  {
    id: 6,
    question: "Can I Track My Link Performance with SmartLnks?",
    answer:
      "Absolutely! Our analytics are so detailed, they'd make a detective jealous. Track everything from clicks to the color of your users' shoes (just kidding on the shoes part).",
  },
  {
    id: 7,
    question: "Are There Limits on the Number of Links I Can Create?",
    answer:
      "Limits? What limits? Create links till your heart's content - we won’t stop you. Go wild, make 100, 1,000, or even more!",
  },
  {
    id: 8,
    question: "Does SmartLnks Support Deep Linking?",
    answer:
      "Yep, we go deep. Deep linking with us is like finding a shortcut in a traffic jam. Direct your users right where you want them, no detours.",
  },
]);

const activeFaq = ref(null);

const oddFaqs = computed(() => faqs.value.filter((faq) => faq.id % 2 !== 0));
const evenFaqs = computed(() => faqs.value.filter((faq) => faq.id % 2 === 0));

const handleToggleCallback = (faqId) => {
  activeFaq.value = activeFaq.value === faqId ? null : faqId;
};
</script>

<template>
  <section
    id="faq"
    class="relative z-30 overflow-hidden bg-white dark:bg-dark pb-12 lg:pb-[90px]"
  >
    <div class="container lg:w-[80%] w-[90%] mx-auto">
      <div class="-mx-4 flex flex-wrap">
        <div class="w-full px-4">
          <div class="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
            <span class="mb-2 block text-lg font-semibold text-primary"> FAQ </span>
            <h2
              class="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-[40px] sm:leading-[48px]"
            >
              Got Questions? We've Got Answers!
            </h2>
            <h3 class="text-base text-body-color dark:text-dark-6">
              Check out our FAQs for all you need to know about SmartLnks. Still curious?
              Chat with us for more!
            </h3>
          </div>
        </div>
      </div>                          

      <div class="-mx-4 flex flex-wrap">
        <div class="w-full px-4 lg:w-1/2">
          <template :key="faq.id" v-for="faq in oddFaqs">
            <HomeFaqAccordion
              :id="faq.id"
              :question="faq.question"
              :answer="faq.answer"
              :activeId="activeFaq"
              @handleToggle="handleToggleCallback"
            />
          </template>
        </div>

        <div class="w-full px-4 lg:w-1/2">
          <template :key="faq.id" v-for="faq in evenFaqs">
            <HomeFaqAccordion
              :id="faq.id"
              :question="faq.question"
              :answer="faq.answer"
              :activeId="activeFaq"
              @handleToggle="handleToggleCallback"
            />
          </template>
        </div>
      </div>

      <p class="my-10">
        Got more questions? Feel free to chat with us for any additional information or
        help!
      </p>
    </div>

    <!-- <div class="absolute bottom-0 right-0 z-[-1]">
      <svg
        width="1440"
        height="886"
        viewBox="0 0 1440 886"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="1308.65"
            y1="1142.58"
            x2="602.827"
            y2="-418.681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3056D3" stop-opacity="0.36" />
            <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
            <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
          </linearGradient>
        </defs>
      </svg>
    </div> -->
  </section>
</template>
