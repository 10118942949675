<script setup>
// ref https://merakiui.com/components/marketing/pricing

const plans = [
  {
    name: "The All-Inclusive Freebie",
    description:
      "Jump into SmartLnks' 'All-Inclusive Freebie'. It's six months of our best features at a price even your wallet can't resist – absolutely free! Manage links like a pro, no strings attached.",
    price: "$0.00",
    period: "6 Months",
    features: [
      {
        name: "Unlimited branded links",
        tooltipText: "Create as many branded links as you like, no limits!",
        available: true,
      },
      {
        name: "Unlimited custom domains",
        tooltipText: "Personalize your links with custom domains galore.",
        available: true,
      },
      {
        name: "Free SSL certificate",
        tooltipText: "Secure your custom domains with free SSL, because safety first!",
        available: true,
      },
      {
        name: "Advanced link features",
        tooltipText:
          "Access a suite of sophisticated link functionalities.Enjoy scheduling links, setting click limits, creating password-protected URLs, targeting specific geographies and devices, custom warnings, and UTM data management. Plus, delve into the world of deep links for a more nuanced link control.",
        available: true,
      },
      {
        name: "Unlimited link history",
        tooltipText: "Keep track of your link history without any caps.",
        available: true,
      },
      {
        name: "Root domain redirect",
        tooltipText: "Redirect your root domain easily and efficiently.",
        available: true,
      },
      {
        name: "Custom QR Code",
        tooltipText: "Create your own QR codes for easy sharing and scanning.",
        available: true,
      },
      {
        name: "Link Analytics",
        tooltipText:
          "Gain insights with comprehensive analytics on every link you create.",
        available: true,
      },
      {
        name: "Multi-Platform Sharing",
        tooltipText:
          "Effortlessly share your links across various social media platforms.",
        available: true,
      },
      {
        name: "Link Collections",
        tooltipText: "Organize your links into collections for efficient management.",
        available: true,
      },
      {
        name: "Automated Link Reports",
        tooltipText: "Receive automated reports on your link performance and analytics.",
        available: true,
      },
      {
        name: "Priority support",
        tooltipText: "Jump to the front of the line with our priority customer support.",
        available: true,
      },
      {
        name: "& much more",
        tooltipText:
          "Discover even more advanced features tailored to elevate your link management experience. There's always something new to explore!",
        available: true,
      },
    ],
    paymentType: "Absolutely Free",
    buttonLabel: "Sign Up Free",
    planType: "all-inclusive",
  },
  //   {
  //     name: "Premium",
  //     price: "$50.00",
  //     period: "life time",
  //     features: [
  //       "All limited links",
  //       "Own analytics platform",
  //       "Chat support",
  //       "Optimize hashtags",
  //       "Mobile app",
  //       "Unlimited users",
  //     ],
  //     paymentType: "One time payment",
  //     buttonLabel: "Start Now",
  //     planType: "premium",
  //   },
];
</script>
<template>
  <div id="pricing" class="container relative z-30 px-6 py-8 mx-auto">
    <div class="xl:items-center xl:-mx-8 xl:flex">
      <div class="flex flex-col items-center xl:items-start xl:mx-8">
        <h2
          class="text-2xl font-medium text-gray-800 capitalize lg:text-3xl dark:text-white"
        >
          Our (Currently) Free Pricing Plan
        </h2>

        <div class="mt-4">
          <span class="inline-block w-40 h-1 bg-primary rounded-full"></span>
          <span class="inline-block w-3 h-1 mx-1 bg-primary rounded-full"></span>
          <span class="inline-block w-1 h-1 bg-primary rounded-full"></span>
        </div>

        <h3 class="mt-4 font-medium text-gray-500 dark:text-gray-300">
          No Plans, No Hassle - Just Sign Up for Total Access!
        </h3>

        <!-- <p class="md:w-[28rem] mt-10 text-gray-500 dark:text-gray-300">
            "Who said the best things in life aren't free? At SmartLnks, we're defying the
            norms. For the next six months, enjoy our top-tier smart link generator and
            URL shortener at the unbeatable price of $0. Yes, you read that right. Zero,
            nada, nothing! Choose your plan and experience SmartLnks magic without any
            cost. But hurry, this offer won't last forever – unless we forget to change it
            back!"
          </p> -->

        <!-- <a
            href="#"
            class="flex items-center mt-4 -mx-1 text-sm text-gray-700 capitalize dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500"
          >
            <span class="mx-1">read more</span>
            <svg
              class="w-4 h-4 mx-1 rtl:-scale-x-100"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> -->
      </div>

      <div class="flex-1 xl:mx-8">
        <div
          class="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0"
        >
          <div
            v-for="plan in plans"
            :key="plan.planType"
            class="max-w-lg mx-auto border rounded-lg md:mx-4 dark:border-gray-700"
          >
            <div class="p-6">
              <h3
                class="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white"
              >
                {{ plan.name }}
              </h3>

              <p class="mt-4 text-gray-500 dark:text-gray-300 text-pretty">
                {{ plan.description }}
              </p>

              <h2
                class="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300"
              >
                {{ plan.price }}
                <span class="text-base font-medium">/{{ plan.period }}</span>
              </h2>

              <p class="mt-1 text-gray-500 dark:text-gray-300">
                {{ plan.paymentType }}
              </p>

              <HomePricingTableGetStartedBtn :label="plan.buttonLabel" />
            </div>

            <hr class="border-gray-200 dark:border-gray-700" />

            <div class="p-6">
              <h4
                class="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white"
              >
                What’s included:
              </h4>

              <ul class="mt-8 space-y-4">
                <li
                  v-for="feature in plan.features"
                  :key="feature.name"
                  class="flex items-center"
                >
                  <SvgTickFilled v-if="feature.available" />
                  <SvgBan v-else />

                  <span class="mx-4 text-gray-700 dark:text-gray-300">{{
                    feature.name
                  }}</span>
                  <i
                    v-if="feature.tooltipText"
                    v-tooltip="{
                      value: feature.tooltipText,
                      class: 'extra-small-xl-tooltip',
                    }"
                    class="pi pi-question-circle"
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
