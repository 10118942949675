<script setup>
definePageMeta({
  middleware: ["remove-token"],
});

useServerSeoMeta({
  title: "The Smartest, Secure Smart Link Generator and App Opener",
  ogTitle: "The Smartest, Secure Smart Link Generator and App Opener",
  description:
    "The globe's best free, secure smart link generator and App Opener. Craft and target your links with the most innovative tool on the internet.",
  ogDescription:
    "The globe's best free, secure smart link generator and App Opener. Craft and target your links with the most innovative tool on the internet.",
  ogImage: "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/smartlnks-index.png",
  twitterCard: "summary_large_image",
});

// v1. - Open in Apps Made Easy with Smartlinks - The Best App Opener

// TITLE
// - SmartLnks: The Smartest Way to Create and Manage Links
// - SmartLnks: Your All-in-One URL Shortener and App Opener
// - SmartLnks: The Smart Link Generator That Does It All
// - SmartLnks: The Smartest Smart Link Generator on the Market

// DESCRIPTION
// - SmartLnks: The smart link generator that helps you get more clicks, shares, and conversions. Sign up today!
// - The all-in-one smart link generator for creating custom links, tracking link performance, and targeting links to specific audiences and devices. Sign up today!
// - The all-in-one link management solution! Organize, schedule, openinapp, and analyze clicks with ease. Target specific devices & regions, set redirects, & more
const disableRightClick = (event) => {
  event.preventDefault();
};
</script>

<template>
  <div class="bg-white text-center smartlnks-index">
    <div @contextmenu="disableRightClick">
      <LazySvgPattern class="fixed opacity-[0.07] z-[1] md:h-auto h-full" />
      <HomeDecoration />
      <HomeHeroSection />
      <!-- <LazyHomeGlobeContainer @contextmenu="disableRightClick" /> -->
      <HomeQuickFeatures />
      <!-- <LazyHomeCreateLinkFeatures v-if="!$device.isMobile" /> -->
      <HomeOurStory />
      <HomeOurMission />
      <HomePricingTable />
      <HomeServices />
      <!-- <HomeDownloadOurApps /> -->
      <HomeTestimonials />
      <HomeContribution />
      <HomeFounderQuote />
    </div>

    <HomeBlogsAndArticles />

    <div @contextmenu="disableRightClick">
      <HomeFaq />
      <HomeTalkToUs />
      <CheckClipboard />
    </div>
    <!-- <HomeSmartYTLinkDialog /> -->
  </div>
</template>

<style scoped></style>
