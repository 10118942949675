<script setup>
// const apis = new APIS();
// const request_uri = `${apis.BASE_URL + apis.FETCH_BLOGS}`;
// const options = {
//   method: "GET",
//   headers: {
//     "Content-Type": "application/json",
//   },
//   params: {
//     url_id: undefined,
//   },
// };
// const { data: blogsData, error } = await useAsyncData(() => $fetch(request_uri, options))

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);

const blogs = [
  {
    link: "/blog/dive-into-app-opener-and-link-opener-target-pixel-utm-builder-more",
    linkTitle:
      "Dive Into App Opener and Link Opener: Target Pixel, UTM Builder & More | by Smartlnks.com | Mar, 2023",
    linkDescription:
      "Discover the power of Smartlnks app and link openers. Use target pixels, UTM builders, and more to customize your links and improve your marketing…",
    linkImage: "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/blog/blog-01.webp",
    imageAltText: "blog image",
  },
  {
    link: "/blog/take-your-links-to-the-next-level-with-smartlnks-open-in-appfeature",
    linkTitle:
      "Take Your Links to the Next Level With Smartlnks' Open in App Feature | by Smartlnks.com | Mar, 2023 | Medium",
    linkDescription:
      "Looking for a way to improve your links' user experience? Try Smartlnks' Open in App feature and launch links directly in your native apps. Discover the benefits…",
    linkImage: "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/blog/blog-05.webp",
    imageAltText: "blog image",
  },
  {
    link: "/blog/open-links-in-their-native-app-easily-w-openinapp",
    linkTitle:
      "Open Links in Their Native App Easily W/ Smartlnks' Openinapp | by Smartlnks.com | Mar, 2023 | Medium",
    linkDescription:
      "Smartlnks' Openinapp feature lets you seamlessly open links in their native app. Improve your user experience and boost engagement. Learn more today…",
    linkImage: "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/blog/blog-03.webp",
    imageAltText: "blog image",
  },
];
</script>
<template>
  <div class="my-52 relative z-30" id="blogs&articles">
    <h2 class="font-bold text-2xl mb-8 tracking-normal relative">BLOGS & ARTICLES</h2>

    <Carousel
      :value="blogs"
      :numVisible="3"
      :numScroll="3"
      :circular="true"
      :showIndicators="false"
      :responsiveOptions="responsiveOptions"
      class="lg:w-[80%] w-[90%] mx-auto"
    >
      <template #item="slotProps">
        <CardStaticTwitter
          :customClass="' md:w-96'"
          :link="slotProps.data.link"
          :linkTitle="slotProps.data.linkTitle"
          :linkDescription="slotProps.data.linkDescription"
          :linkImage="slotProps.data.linkImage"
          :imageAltText="slotProps.data.imageAltText"
        />
      </template>
    </Carousel>
  </div>
</template>

<style></style>