<template>
  <h2 class="px-6 text-base md:text-lg text-gray-600 font-inter">
    Maximize Your Clicks, Capture Every Conversion.
  </h2>
  <h1
    class="mt-5 text-[2rem] font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-[3.2rem] lg:leading-tight font-pj select-none"
  >
    The Game-Changing Smart Link Generator for
    <span class="relative inline-flex">
      <span
        class="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"
      ></span>
      <span class="relative" id="typewriter-span"> Everyone. </span>
    </span>
  </h1>
</template>
