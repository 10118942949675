<script setup>
import { form, formV$ } from "~/validations/homeLinkFormRules";

const showSocialIcon = computed(
  () => !formV$.value.destinationURL.$error && form.destinationURL.length > 0
);
</script>

<template>
  <div class="relative w-full flex items-center justify-center z-30">
    <span class="p-input-icon-left w-full flex items-center justify-center">
      <span class="absolute left-2.5 z-50">
        <i>
          <CustomSocialIcons
            v-if="showSocialIcon"
            :url="form.destinationURL"
            :dynamicClass="'!h-9 !w-9'"
          />
        </i>
      </span>
      <input
        type="search"
        id="search"
        class="block w-full p-5 pl-5 pr-32 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black z-30"
        :class="{
          '!pl-14': showSocialIcon,
        }"
        placeholder="Enter or paste any url"
        required
        v-model.trim="formV$.destinationURL.$model"
      />
    </span>
    <HomeSmartenItBtn />
  </div>
</template>

<style></style>
