<script setup></script>
<template>
  <div class="mt-44 mb-32 relative">
    <div class="flex justify-center">
      <div class="lg:w-[80%] w-[90%]">
        <h2
          class="leading-loose font-bold lg:mx-24 md:mx-24 text-[1.5rem] lg:text-[2.6rem] md:text-[2.6rem]"
        >
          Get Involved and Help Shape the Future of
          <br class="hidden md:block" />
          <span
            class="bg-primary opacity-80 text-white px-2 rounded-lg lg:rounded-xl md:rounded-xl"
            >SmartLnks</span
          >
          , Join Our Community!
        </h2>
        <p class="text-700 md:text-xl md:font-medium mt-10 lg:mx-28 md:mx-28 text-pretty">
          Here at Smartlinks, we're tirelessly crafting the ultimate tool for link
          management, because obviously, the internet is begging for it. It's an
          ever-changing beast, and we're adding new features faster than you can say
          'link'. But even geniuses need a little help.
        </p>
        <p class="text-700 md:text-xl md:font-medium mt-10 lg:mx-28 md:mx-28 text-pretty">
          That’s why we need you – yes, you! Join us in building what we humbly believe
          will be the best link management platform the world has ever seen. Your feedback
          and ideas are crucial (because, let's face it, we don’t know everything).
        </p>
        <p class="text-700 md:text-xl md:font-medium mt-10 lg:mx-28 md:mx-28 text-pretty">
          Whether you're a coding wizard, a creative genius, a marketing guru, or just
          someone who's obsessed with links, we want you on our team. Help us make
          Smartlinks not just good, but mind-blowingly awesome. Together, we'll redefine
          link management, one clever feature at a time.
        </p>
        <NuxtImg
          provider="awsS3"
          class="mx-auto select-none pointer-events-none mt-16 md:h-72"
          src="/contributions.png"
          alt="Smartlnks Contribution"
        />
      </div>
    </div>
  </div>
</template>

<style></style>
